var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ls-form-lang"},_vm._l((_vm.lang),function(lx,li){return _c('div',{key:li,staticClass:"ls-form-lang-item"},[_vm._t(lx,null,{"form":{
        ..._vm.form, 
        //propagate prop with language
        prop: _vm.prop ? (_vm.prop+'.'+lx) : null, 
        label: !li ? _vm.label : null,
        help: (!li && _vm.$slots.help) ? _vm.$slots.help : null
      }})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }