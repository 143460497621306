import stringHelpers from './stringHelpers';
import { IBAN } from 'ibankit';

export default {
  validateRequired(rule, value, callback) {
    if(value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0))
      callback(new Error(Vue.options.i18n.t('customRules.required')));
    else
      callback();
  },

  validateCheckbox(rule, value, callback) {
    if(value !== true)
      callback(new Error(Vue.options.i18n.t('customRules.required')));
    else
      callback();
  },

  validateEmail(rule, value, callback) {
    var re = /^([a-z0-9\+_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,12}$/i;
    if(!value)
      callback();
    else if(re.test(String(value).toLowerCase()))  
      callback();
    else
      callback(new Error(Vue.options.i18n.t('customRules.email')));
  },

  //number validator
  validateNumber(rule, value, callback) {
    if(!value)
      callback();
    else if(typeof value == 'number')
      callback();
    else
      callback(new Error(Vue.options.i18n.t('customRules.number')));
  },

  //number validator (string)
  validateNumberString(rule, value, callback) {
    if(!value)
      callback();
    else if(/^\d+$/.test(value))
      callback();
    else
      callback(new Error(Vue.options.i18n.t('customRules.numberString')));
  },

  //"rodne cislo"
  validateRC(rule, value, callback) {
    if(!value)
      callback();
    else if (!stringHelpers.parseRC(value)?.date)
      callback(new Error(Vue.options.i18n.t('customRules.RC')));
    else
      callback();

  },

  //bank account number in "standard" format or in IBAN/BBAN
  validateCU(rule, value, callback) {
    function valid(value) {

      //split to account nubmer and bank code
      var cu = value.split('/');
      
      //check bank code
      if(!cu || !cu[1] || cu[1].length != 4)
        return false;

      //check account number
      var isOk = false;

      var regex = /(([0-9]{0,6})-)?([0-9]{1,10})/g;
      var match = regex.exec(cu[0]);

      if(!match)
        return false;

      if(!match[1])
        match[1] = "";

      if(!match[3])
        return false;

      var first = match[1].slice(0, -1).padStart(6, '0').split('');
      var second = match[3].padStart(10, '0').split('');

      // FIRST PART - MODULO 11
      isOk = (10*first[0] 
          + 5*first[1] 
          + 8*first[2] 
          + 4*first[3] 
          + 2*first[4] 
          + 1*first[5])
        % 11 == 0;

      if(isOk === false) {
        return false;
      }

      // SECOND PART - MODULO 11
      isOk = ( 6*second[0] 
          + 3*second[1] 
          + 7*second[2] 
          + 9*second[3] 
          +10*second[4] 
          + 5*second[5] 
          + 8*second[6] 
          + 4*second[7] 
          + 2*second[8] 
          + 1*second[9])
        % 11 == 0;

      if(isOk == false) {
        return false;
      }

      return true;
    }

    if(!value)
      callback();
    else if(!valid(value) && !IBAN.isValid(value))
      callback(new Error(Vue.options.i18n.t('customRules.CU')));
    else
      callback();
  },

  //ICO
  validateICO(rule, value, callback) {
    function valid(x) {
      try {
        var a = 0;
        if(x.length == 0) return true;
        if(x.length != 8) throw 1;
        var b = x.split('');
        var c = 0;
        for(var i = 0; i < 7; i++) a += (parseInt(b[i]) * (8 - i));
        a = a % 11;
        c = 11 - a;
        if(a == 1) c = 0;
        if(a == 0) c = 1;
        if(a == 10) c = 1;
        if(parseInt(b[ 7]) != c) throw(1);
      }
      catch(e) {
        return false;
      }
      return true;
    }

    if(!value)
      callback();
    else if (!valid(value))
      callback(new Error(Vue.options.i18n.t('customRules.ICO')));
    else
      callback();
  },
      
  //simple phone number validation
  validatePhone(rule, value, callback) {
    if(!value)
      callback();
    else if(!/^[+]?[()/0-9. -]{9,}$/.test(value))
      callback(new Error(Vue.options.i18n.t('customRules.phone')));
    else
      callback();
  },

  //czech driving licence
  validateDrivingLicenceCZ(rule, value, callback) {
    if(!value)
      callback();
    else if(value.length != 8)
      callback(new Error(Vue.options.i18n.t('customRules.drivingLicenceCZ.format')));
    else if(!/[aeiouyAEIOUY]/.test(value.charAt(0)))
      callback(new Error(Vue.options.i18n.t('customRules.drivingLicenceCZ.firstLetter')));
    else if(!/[a-zA-Z]/.test(value.charAt(1)))
      callback(new Error(Vue.options.i18n.t('customRules.drivingLicenceCZ.secondLetter')));
    else if(!/^\d+$/.test(value.substring(2)))
      callback(new Error(Vue.options.i18n.t('customRules.drivingLicenceCZ.format')));
    else
      callback();
  },

  //valid before today (eg birthdate)
  tillToday(rule, value, callback) {
    var inputDate = new Date(value);
    var todayDate = new Date().setHours(0,0,0,0);
    
    if(!value)
      callback();
    else if(inputDate<todayDate)
      callback(new Error(Vue.options.i18n.t('customRules.tillToday')));
    else
      callback();
  },

  //valid after today (eg expiration date)
  fromToday(rule, value, callback) {
    var inputDate = new Date(value);
    var todayDate = new Date().setHours(0,0,0,0);
    
    if(!value)
      callback();
    else if(inputDate>todayDate)
      callback(new Error(Vue.options.i18n.t('customRules.fromToday')));
    else
      callback();
  },
}

export var messages = {
/* translation table */
  cz: {
    required: 'Toto pole je povinné',
    email: 'Nesprávný formát e-mailu',
    number: 'Lze vepsat pouze číslo',
    numberString: "Lze vepsat pouze číslice",
    RC: 'Nesprávný formát rodného čísla',
    CU: 'Nesprávný formát čísla účtu',
    ICO: 'Nesprávný formát IČ',
    phone: 'Nesprávný formát telefonního čísla',
    drivingLicenceCZ: {
      format: 'Nesprávný formát čísla řidičského průkazu',
      firstLetter: 'První písmeno musí být samohláska',
      secondLetter: 'Druhý znak musí být písmeno',
    },
    tillToday: "Datum nesmí předcházet dnešnímu dni",
    fromToday: "Datum musí předcházet dnešnímu dni",
  },
  sk: {
    required: 'Toto pole je povinné',
    email: 'Nesprávny formát e-mailu',
    number: 'Možno vpísať iba číslo',
    numberString: "Možno vpísať iba číslice",
    RC: 'Nesprávny formát rodného čísla',
    CU: 'Nesprávny formát čísla účtu',
    ICO: 'Nesprávny formát IČO',
    phone: 'Nesprávny formát telefónneho čísla',
    drivingLicenceCZ: {
      format: 'Nesprávny formát čísla vodičského preukazu',
      firstLetter: 'Prvé písmeno musí byť samohláska',
      secondLetter: 'Druhý znak musí byť písmeno',
    },
    tillToday: "Dátum nesmie predchádzať dnešnému dňu",
    fromToday: "Dátum musí predchádzať dnešnému dňu",
  },    
  en: {
    required: 'This field is mandatory',
    email: 'E-mail in wrong format',
    number: 'Only numbers are allowed',
    numberString: 'Only numbers are allowed',
    RC: 'Incorrect National ID number',
    CU: 'Incorrect bank account number',
    ICO: 'Incorect CIN',
    phone: 'Incorrect phone number',
    drivingLicenceCZ: {
      format: "Incorrect driver's license number",
      firstLetter: 'The first letter must be a vowel',
      secondLetter: 'The second character must be a letter',
    },
    tillToday: "The date cannot precede today",
    fromToday: "The date must precede today",
  },
  "hu": {
    "required": "Ez a mező kötelező",
    "email": "E-mail rossz formátumban",
    "number": "Csak a számok megengedettek",
    "numberString": "Csak a számok megengedettek",
    "RC": "Helytelen nemzeti azonosító szám",
    "CU": "Helytelen bankszámlaszám",
    "ICO": "Kiabál",
    "phone": "Helytelen telefonszám",
    "drivingLicenceCZ": {
      "format": "Helytelen vezetői engedély száma",
      "firstLetter": "Az első betűnek magánhangzónak kell lennie",
      "secondLetter": "A második karakternek levélnek kell lennie",
    },
    "tillToday": "A dátum nem tudja megelőzni ma",
    "fromToday": "A dátumnak ma meg kell előznie",
  },
  "pl": {
    "required": "To pole jest obowiązkowe",
    "email": "E-mail w niewłaściwym formacie",
    "number": "Dozwolone są tylko liczby",
    "numberString": "Dozwolone są tylko liczby",
    "RC": "Nieprawidłowy krajowy numer identyfikacyjny",
    "CU": "Nieprawidłowy numer konta bankowego",
    "ICO": "Incorect Cin.",
    "phone": "Nieprawidłowy numer telefonu",
    "drivingLicenceCZ": {
      "format": "Nieprawidłowy numer prawa jazdy",
      "firstLetter": "Pierwsza litera musi być samogłoską",
      "secondLetter": "Druga postać musi być literą",
    },
    "tillToday": "Data nie może poprzedzać dzisiaj",
    "fromToday": "Data musi poprzedzać dzisiaj",
  }
}
