<!-- <ls-docx-viewer :doc="doc"></ls-docx-viewer> uses default file -->
<!-- <ls-docx-viewer :doc="doc" :file="Výpověď.docx"></ls-docx-viewer> uses "file" -->
<!-- <ls-docx-viewer :doc="doc" url="https://calibre-ebook.com/downloads/demos/demo.docx"></ls-docx-viewer> load external URL -->
<template>
  <div class="relative" v-loading="linkLoading" :element-loading-text="$t('viewer.loading')" element-loading-background="rgba(255, 255, 255, 1.0)">
    <div v-if="link">
      <iframe class="ms-iframe" v-if="link" :key="key" id="office" :src="link" :style="'width: 100%; height: '+height+'; min-height: initial'" frameborder='0'/>
      <div class="ms-hide-toolbar">
        {{ $t('viewer.retry') }} <el-button plain type="primary" size="mini" @click="key++">{{ $t('viewer.retryAction') }}</el-button>
      </div>
    </div>
    <div v-else-if="error">
    <el-alert
      :title="$t('viewer.error')"
      :description="$t('viewer.errorDesc')"
      type="error"
      :closable="false"
      show-icon>
    </el-alert>
  	</div>
    <div v-else :style="'width: 100%; height: '+height+'; min-height: initial'"></div>
  </div>
</template>

<style lang="scss" scoped>
.ms-iframe {
  margin-bottom: 16px;
}
.ms-hide-toolbar::v-deep {
  height: 40px; //24px
  line-height: 40px;
  margin-top: -40px; 
  background: white; 
  position: absolute; 
  width: 100%;
  font-size: 0.9em;
  text-align: center;
  .el-button {
    font-size: 0.9em;
  }
}
</style>

<script>
  export default {
    inject: {
      template: {
        default: null
      },
      savingProcess: {
        default: null
      }
    },    
    props: {
      doc: Object,
      file: {
      	type: String,
      	default: null
      },
      url: {
      	type: String | Promise,
      	default: null
      },
      height: {
      	type: String,
      	default: "50vh"
      },
      keep: {
        type: Boolean,
        default: false
      },
      type: {
        type: String,
        default: "pdf"
      }
    },
    data () {
      return {
        key: 0,
        link: null,
        linkLoading: true,
        error: false,
      }
    },
    mounted: async function() {
      if(this.url !== null) {//just open external docx - no "doc" data required
        this.link = this.getMsUrl(await this.url);
        this.linkLoading = false;
      }
      else {//generate docx, temporarily save it and preview it
        if(this.doc) {
          var fn = this.getDownloadPdfUrl;
          if(this.type == "docx")
            fn = this.getDownloadUrl;

          fn(this.file, this.keep).then(async (value) => {
            this.link = this.getMsUrl(await value);

            await this.sleep(2000);
            this.key++;

            this.linkLoading = false;
          }).catch(res => {
            this.error = true;
            this.linkLoading = false;
            console.log(res);
            this.$sentry.captureException(res);
          });
        }
        else {//no "doc" data specified!
          this.error = true;
          this.linkLoading = false;
          throw new Error('Specify :doc="doc" or url for ls-viewer component!');
        }
      }
    },
    methods: {
      getMsUrl(link) {
        return 'https://view.officeapps.live.com/op/embed.aspx?src='+link+'&action=embedview&wdPrint=0&wdEmbedCode=0';
      }

    }
  }
</script>

<i18n>
  {
    "cz": {
      "viewer": {
        "loading": "Připravuji náhled dokumentu...",
        "retry": "Náhled se nechce zobrazit?",
        "retryAction": "Kliknutím sem ho zkuste načíst znovu.",
        "error": "Chyba!",
        "errorDesc": "Náhled dokumentu se nepodařilo zobrazit."
      }
    },
    "sk": {
      "viewer": {
        "loading": "Pripravujem náhľad dokumentu...",
        "retry": "Náhľad sa nechce zobraziť?",
        "retryAction": "Kliknutím sem ho skúste načítať znovu.",
        "error": "Chyba!",
        "errorDesc": "Náhľad dokumentu sa nepodarilo zobraziť."
      }
    },
    "en": {
      "viewer": {
        "loading": "Preparing document preview ...",
        "retry": "Preview not showing?",
        "retryAction": "Try to reload it by clicking here.",
        "error": "Error!",
        "errorDesc": "The document preview could not be displayed."
      } 
    },
    "pl": {
      "viewer": {
        "loading": "Przygotowanie podglądu dokumentu ...",
        "error": "Błąd!",
        "errorDesc": "Nie można wyświetlić podglądu dokumentu."
      } 
    },
    "hu": {
      "viewer": {
        "loading": "A dokumentum előnézete ...",
        "error": "Hiba!",
        "errorDesc": "A dokumentum előnézete nem jeleníthető meg."
      } 
    }
  } 
</i18n>
