var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"el-button",class:[
      _vm.type ? 'el-button--' + _vm.type : '',
      _vm.buttonSize ? 'el-button--' + _vm.buttonSize : '',
      {
        'is-disabled': _vm.buttonDisabled,
        'is-loading': _vm.loading,
        'is-plain': _vm.plain,
        'is-round': _vm.round,
        'is-circle': _vm.circle
      }
    ],attrs:{"href":!_vm.buttonDisabled ? _vm.href : undefined,"disabled":_vm.buttonDisabled || _vm.loading,"autofocus":_vm.autofocus,"type":_vm.nativeType,"target":_vm.target}},[(_vm.loading)?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),_vm._v(" "),(_vm.icon && !_vm.loading)?_c('i',{class:_vm.icon}):_vm._e(),_vm._v(" "),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }