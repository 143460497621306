import { render, staticRenderFns } from "./_statutar.vue?vue&type=template&id=01a3f4b8&scoped=true"
import script from "./_statutar.vue?vue&type=script&lang=js"
export * from "./_statutar.vue?vue&type=script&lang=js"
import style0 from "./_statutar.vue?vue&type=style&index=0&id=01a3f4b8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01a3f4b8",
  null
  
)

/* custom blocks */
import block0 from "./_statutar.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports